namespace Publishers {
  let _listenersEsc = [];

  // Esc ------------------------------------------------------------------------

  export function SubscribeEsc(listenerEsc: Function): Function {
    _listenersEsc.push(listenerEsc);
    return listenerEsc;
  }

  export function UnsubscribeEsc(listenerEsc: Function): void {
    _listenersEsc = _listenersEsc.filter(l => l !== listenerEsc);
  }

  // Dispatch -------------------------------------------------------------------

  export function Dispatch(evt: KeyboardEvent): void {
    switch (evt.key) {
      case 'Escape':
        _listenersEsc.forEach(l => l());
        break;
      default:
        break;
    }
  }
}

export { Publishers };
